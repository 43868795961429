import React, { useEffect, useState } from 'react'

const Loader = (pageContext) => {
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    setTimeout(function () {
      setLoading(false)
    }, 1000)
  }, [pageContext])

  return (
    <div
      id="pageLoader"
      className="fixed top-0 left-0 bg-blue w-screen h-screen z-god pointer-events-none opacity-100 transition-all duration-200"
      style={{
        opacity: `${isLoading ? 1 : 0}`
      }}
    >
    </div>
  )
}

export default Loader