import React, { useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'

const AnimatedDots = (props) => {
  const dotsContainer = useRef(null)
  let illuminatedDots = []
  const [ref, inView] = useInView()

  useEffect(() => {
    setInterval(illuminateRandomDots, 2000)
  }, [])

  useEffect(() => {
    if (inView) {
      dotsContainer.current.style.opacity = 1
    } else {
      dotsContainer.current.style.opacity = 0
    }
  }, [inView])

  function illuminateRandomDots(num = 20) {
    illuminatedDots.forEach(illuminatedDot => {
      illuminatedDot.setAttribute('class', 'dot')
      illuminatedDot.style.opacity = 0
    })
    illuminatedDots = []
    const allDots = dotsContainer.current && dotsContainer.current !== null && dotsContainer.current.querySelectorAll('i')

    if (allDots !== null) {
      for (let i = 0; i < num; i++) {
        const randomDot = allDots[Math.floor(Math.random() * allDots.length)]
        randomDot.setAttribute('class', 'dot active-border')
        randomDot.style.opacity = `${i}%`
        illuminatedDots.push(randomDot)
      }
      for (let i = 0; i < num; i++) {
        const randomDot = allDots[Math.floor(Math.random() * allDots.length)]
        randomDot.setAttribute('class', 'dot active-full')
        randomDot.style.opacity = `${i}%`
        illuminatedDots.push(randomDot)
      }
    }
  }

  return (
    <div
      ref={dotsContainer}
      className={`dots ${props.className} transition-all duration-500`}
    >
      <div ref={ref} />
      <DotsGroups />
      <DotsGroups />
      <DotsGroups />
      <DotsGroups />
      <DotsGroups />
      <DotsGroups />
      <DotsGroups />
      <DotsGroups />
      <DotsGroups />
      <DotsGroups />
    </div>
  )
}

const DotsGroup = () => {
  return (
    <div className="dots-group">
      <span><i className="dot" /></span><span><i className="dot" /></span><span><i className="dot" /></span><span><i className="dot" /></span><span><i className="dot" /></span>
      <span><i className="dot" /></span><span><i className="dot" /></span><span><i className="dot" /></span><span><i className="dot" /></span><span><i className="dot" /></span>
      <span><i className="dot" /></span><span><i className="dot" /></span><span><i className="dot" /></span><span><i className="dot" /></span><span><i className="dot" /></span>
      <span><i className="dot" /></span><span><i className="dot" /></span><span><i className="dot" /></span><span><i className="dot" /></span><span><i className="dot" /></span>
      <span><i className="dot" /></span><span><i className="dot" /></span><span><i className="dot" /></span><span><i className="dot" /></span><span><i className="dot" /></span>
    </div>
  )
}

const DotsGroups = (props) => {
  return (
    <>
      <DotsGroup />
      <DotsGroup />
      <DotsGroup />
      <DotsGroup />
      <DotsGroup />
      <DotsGroup />
      <DotsGroup />
      <DotsGroup />
      <DotsGroup />
      <DotsGroup />
      <DotsGroup />
      <DotsGroup />
      <DotsGroup />
      <DotsGroup />
    </>
  )
}

export default AnimatedDots
