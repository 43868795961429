import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import AnimatedDots from '@elements/animated-dots'
import AnimatedBackground from '@base/elements/animated-background'

const FooterDynamic = (props) => {
  return (
    <>
      <AnimatedBackground className="absolute w-full h-[200%] left-0 bottom-0 pointer-events-none z-under">
        <StaticImage
          src="../../../assets/img/background-blue-dark.jpg"
          alt=""
          className="h-full w-full gatsby-absolute pointer-events-none z-under -bottom-[20%] object-bottom-offset"
          backgroundColor="#040926"
          loading="lazy"
        />
      </AnimatedBackground>
      <AnimatedBackground 
        className="absolute w-[150px] lg:w-[320px] h-auto -left-12 lg:left-[12%] bottom-32 lg:bottom-44 pointer-events-none z-10"
        translate={[100, 0]}
      >
        <StaticImage
          src="../../../assets/img/flippin-misfits-graffiti-3.png"
          alt="Flippin Misfits Graffiti"
          className="h-full w-full anim-float"
          loading="lazy"
        />
      </AnimatedBackground>
      <AnimatedDots className="hidden lg:flex" />
    </>
  )
}

export default FooterDynamic
