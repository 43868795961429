import React, { useEffect } from 'react'
import { CookiesProvider } from 'react-cookie'

import PageContextProvider from '@base/utils/contexts/page-context'
import FrameworkDevTools from '@base/utils/dev/dev-tools'
import { navIntersect } from '@base/utils/helpers/navigation-scroll-listener'
import Header from '@base/layout/header'
import Footer from '@base/layout/footer'
import DynamicSections from '@base/layout/dynamic'

import FooterDynamic from '@elements/dynamic/footer-dynamic'

const Layout = props => {
  if (!props.globalData) {
    return ''
  }

  let pageContext = props.pageContext
  let localizations = {
    data: [
      ...props.data.localizations
    ],
  }

  const { locale, slug } = pageContext

  // Nav Links listener (for anchor links)
  useEffect(() => {
    let navWrapper = document.querySelector('.nav-light')
    let rootMargin = '-25%'
    const options = {
      rootMargin: rootMargin,
      threshold: 0
    }
    let sections = ''
    let headerLinks = ''
    sections = [...document.querySelectorAll('[data-section]')]
    headerLinks = [...document.querySelectorAll('[data-link]')]
    navIntersect(sections, headerLinks, options, navWrapper)
  }, [pageContext])

  // Site Custom theme
  const headerStyles = props.theme && props.theme.header ? props.theme.header : null
  const footerStyles = props.theme && props.theme.footer ? props.theme.footer : null

  // Global data
  const brandData = props.globalData.brand.edges[0].node
  const seoData = props.globalData.seo.edges[0].node.metadata
  const gameData = ''
  const socialData = props.globalData.global.edges[0].node.socialLinks
  const ctaData = props.globalData.global.edges[0].node.ctaLinks

  // Header specific data
  const mainNavData = props.globalData.global.edges[0].node.mainNav
  const utilNavData = props.globalData.global.edges[0].node.utilNav

  // Dynamic sections specific data
  const newsletterData = ''

  // Footer specific data
  const footerNavData = props.globalData.global.edges[0].node.footer.footerNav
  const footerData = props.globalData.global.edges[0].node.footer
  const gameName = props.globalData.seo.edges[0].node.metadata.metaTitleSuffix

  return (
    <CookiesProvider>
      <PageContextProvider>
        <Header
          data={{ brandData, utilNavData, mainNavData, gameData, socialData, seoData, ctaData }}
          theme={headerStyles}
          pageContext={{ ...pageContext, localizations }}
        />
        {props.children}
        <DynamicSections
          data={{ gameData, newsletterData }}
          globalContext={{ locale, slug, localizations }}
        />
        <div className='hover:text-tertiary' />
        <Footer
          data={{ brandData, footerNavData, gameData, socialData, footerData, gameName }}
          theme={footerStyles}
          pageContext={pageContext}
          customThemeOptions={{
            disableFooter: false,
            removeFooterSocial: true,
            removeFooterStoreLinks: true,
            customTheme: false
          }}
        >
          <FooterDynamic />
        </Footer>
      </PageContextProvider>
      <FrameworkDevTools />
    </CookiesProvider>
  )
}

export default Layout
