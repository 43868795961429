import React from 'react'
import { AnimatePresence } from 'framer-motion'

import LoaderContextProvider from '@base/utils/contexts/loader-context'

import Loader from '@elements/loader'
import Layout from '@layout/layout'

const themeStyle = require('@base/assets/styles/global.scss')
const globalStyle = require('@/assets/styles/global.scss')

// Page Layout
export const wrapPageElement = ({ element, props }) => {
  const browser = typeof window !== 'undefined' && window
  if (!props || !element || !browser) {
    return null
  }
  
  let pageTitle = ''
  let defaultLocaleTitle = ''

  if (props.data && props.data.pageData) {
    pageTitle = props.data.pageData.title
    defaultLocaleTitle = pageTitle
    if (props.data.pageData.localizations && props.data.pageData.localizations.length > 0) {
      defaultLocaleTitle = props.data.pageData.localizations[0].locale === 'en' ? props.data.pageData.localizations[0].title : pageTitle
    }
  }

  return (
    <LoaderContextProvider>
      <AnimatePresence mode="wait">
        <Layout
          data={props.data ? props.data.pageData : ''}
          globalData={props.pageContext.globalData}
          theme={props.pageContext.siteTheme}
          pageContext={{ ...props.pageContext, pageTitle, defaultLocaleTitle }}
        >
          {element}
        </Layout>
      </AnimatePresence>
      <Loader pageContext={props.pageContext} />
    </LoaderContextProvider>
  )
}

// Scroll Update on Route change
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  window.setTimeout(() => window.scrollTo(0, 0), 600)
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), 600)
  } else {
    // if we used the browser's forwards or back button
    const savedPosition = getSavedScrollPosition(location) || [0, 0]
    window.setTimeout(() => window.scrollTo(...savedPosition), 600)
  }
  return false
}